
















































































import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  AI_SCORE_REPORT,
  GET_AI_EVALUATION,
  NEP_INTERVIEW_ID
} from "@/store/modules/candidates/constants";
import { ReportDetail } from "@/store/modules/recruiter/interfaces"; // Update the path
import {
  capitalize_words,
  get_report_icon
} from "@/utils/principle_assessments";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "NepAIResults",
  data() {
    return {
      loading: false,
      report_data: {} as any,
      score_report: [] as ReportDetail[],
      message: "Report not fetched yet. Please try again later.",
      ai_score: false
    };
  },
  async mounted() {
    const result = await this.get_nep_interview_id();
    if (result) {
      const response = await this.get_ai_evaluation({
        interview_id: result.id,
        user_id: this.get_user.id
      });
      if (response) {
        this.report_data.ai_evaluation = response.ai_evaluation_score;
        this.ai_score = true;
      }
    }
    const uid = localStorage.getItem("user_uid");
    if (uid) {
      const data = await this.get_ai_report(uid);
      if (data) {
        this.process_scroe_report(data);
      }
    } else {
      const ai_evaluation = this.get_user.user_data.ai_evaluation;
      this.process_scroe_report(ai_evaluation);
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    }),
    get_score(): number {
      return this.report_data.ai_evaluation;
    }
  },
  methods: {
    ...mapActions("candidate", {
      get_ai_evaluation: GET_AI_EVALUATION,
      get_nep_interview_id: NEP_INTERVIEW_ID,
      get_ai_report: AI_SCORE_REPORT
    }),
    process_scroe_report(ai_evaluation: any) {
      for (const [key, val] of Object.entries(ai_evaluation)) {
        const icon_details = get_report_icon(key);
        const obj = {
          bg_color: icon_details.bg_color,
          color: icon_details.color,
          icon: icon_details.icon,
          key: capitalize_words(key),
          rating: (val as any)?.rating,
          reason: (val as any)?.reason
        };
        this.score_report.push(obj);
      }
    }
  }
});
